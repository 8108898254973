import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

import { GoogleLogin } from "@react-oauth/google";
import { Button, Card, Layout, Typography, message, Modal, List } from "antd";
import {
  RedditOutlined,
  EditOutlined,
  BarChartOutlined,
} from "@ant-design/icons";

const { Title, Paragraph } = Typography;

const RulesModal = ({modalVisible, setModalVisible}) => {
  return (
    <Modal
      title="The Rules"
      visible={modalVisible}
      onCancel={() => setModalVisible(false)}
      footer={
        <Button type="primary" onClick={() => setModalVisible(false)}>
          Got it!
        </Button>
      }
    >
      <List>
        <List.Item>Enter your team name to join the league.</List.Item>
        <List.Item>
          If you have multiple teams, click the "Add Team" button at the top of
          the page and enter the team name.
        </List.Item>
        <List.Item>
          You can add up to one player from each team in the NFL playoffs
        </List.Item>
        <List.Item>
          Once the playoffs start, you cannot change your lineup.
        </List.Item>
        <List.Item>
          You won't be able to see your leaguemate's teams or super bowl guesses
          until rosters are locked-in.
        </List.Item>
        <List.Item>
          Guess the total score of the Super Bowl. At the end of the playoffs,
          if a tiebreaker is necessary, this will be determined by whoever gets
          the closest to the actual score without guessing higher than the
          actual score.
        </List.Item>
        <List.Item>
          To view scoring settings, join the league and click on the "Settings"
          tab above your roster.
        </List.Item>
      </List>
    </Modal>
  );
};

const HomePage = () => {
  const navigate = useNavigate();
  const { isAuthenticated, setToken } = useContext(AuthContext);
  const [modalVisible, setModalVisible] = useState(false);

  const handleGoogleLogin = async (response) => {
    if (response && response.credential) {
      // Save the ID token in Auth Context and Local Storage
      setToken(response.credential, response.expiresIn);

      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/auth/google-signin`,
        response.credential,
        {
          headers: {
            "Content-Type": "text/plain",
          },
        }
      );

      // Navigate to the next page after successful login
      navigate("/view-teams");
    } else {
      message.error("Login failed");
    }
  };

  return (
    <div
      style={{
        marginTop: "16px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Card style={{ textAlign: "center", padding: "16px" }}>
          <Title>The Fantasy Football Playoffs</Title>
          <h2 style={{ fontWeight: "normal", padding: "8px" }}>
            Legends of the Fall
          </h2>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {isAuthenticated ? (
              <Button type="primary" onClick={() => navigate("/view-teams")}>
                View League
              </Button>
            ) : (
              <GoogleLogin
                onSuccess={handleGoogleLogin}
                onError={() => message.error("Login failed")}
              />
            )}
          </div>
        </Card>
      </div>

      <div
        style={{
          backgroundImage: "url('/path-to-header-image.jpg')",
          backgroundSize: "cover",
          padding: "4rem 0",
        }}
      >
        <Title level={2}>Need Help?</Title>
        <p>Contact Mike at <a href="mailto:sierrafch2121@gmail.com">sierrafch2121@gmail.com</a></p>
        {/* How Does It Work Section */}
        <Title level={2}>How Does It Work?</Title>
        <ul style={{ listStyle: "none", padding: 0 }}>
          <li
            style={{
              marginBottom: "1rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            <RedditOutlined
              style={{
                fontSize: "2rem",
                paddingRight: "16px",
                color: "#001529",
              }}
            ></RedditOutlined>
            <div>
              <p style={{ fontSize: 20, marginBottom: 0 }}>
                Join with your team name
              </p>
              <p>If you already have a team, you'll be matched with it!</p>
            </div>
          </li>
          <li
            style={{
              marginBottom: "1rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            <EditOutlined
              style={{
                fontSize: "2rem",
                paddingRight: "16px",
                color: "#001529",
              }}
            ></EditOutlined>
            <div>
              <p style={{ fontSize: 20, marginBottom: 0 }}>Add your players</p>
              <p>You can only have one player from each NFL team!</p>
            </div>
          </li>
          <li
            style={{
              marginBottom: "1rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            <BarChartOutlined
              style={{
                fontSize: "2rem",
                paddingRight: "16px",
                color: "#001529",
              }}
            ></BarChartOutlined>
            <div>
              <p style={{ fontSize: 20, marginBottom: 0 }}>
                Tiebreaker - Super Bowl Score
              </p>
              <p>
                Get as close as possible to the actual total score of the
                superbowl without going over!
              </p>
            </div>
          </li>
          <li
            onClick={() => setModalVisible(true)}
            style={{ cursor: "pointer", color: "#1B92FF" }}
          >
            Click here for more rule details
          </li>
        </ul>
      </div>

      <RulesModal
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
      />
    </div>
  );
};

export default HomePage;
export { RulesModal };
