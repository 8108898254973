import { GoogleOAuthProvider } from "@react-oauth/google";
import React, { useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import ProtectedRoute from "./components/ProtectedRoute";
import { AuthProvider } from "./context/AuthContext";
import CreateLeaguePage from "./pages/CreateLeaguePage";
import HomePage from "./pages/HomePage";
import JoinLeaguePage from "./pages/JoinLeaguePage";
import ViewLeaguePage from "./pages/ViewLeaguePage";
import MainLayout from "./components/MainLayout";
import ViewLeaguesPage from "./pages/ViewLeaguesPage";


import "antd/dist/antd.css";
import "./App.css";

function App() {
  const [authState, setAuthState] = useState({
    token: null,
    isAuthenticated: false,
  });

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setAuthState({ token, isAuthenticated: true });
    }
  }, []);

  const setToken = (token) => {
    localStorage.setItem("token", token);
    setAuthState({
      token: token,
      isAuthenticated: true,
    });
  };

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <AuthProvider value={{ ...authState, setToken }}>
        <Router>
          <MainLayout>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route
                path="/create-league"
                element={<ProtectedRoute component={CreateLeaguePage} />}
              />
              <Route
                path="/join-league"
                element={<ProtectedRoute component={JoinLeaguePage} />}
              />
              <Route
                path="/view-league/:teamId"
                element={<ProtectedRoute component={ViewLeaguePage} />}
              />
              <Route
                path="/view-teams"
                element={<ProtectedRoute component={ViewLeaguesPage} />}
              />
            </Routes>
          </MainLayout>
        </Router>
      </AuthProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
