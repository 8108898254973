import {
  PlusOutlined,
  SwapOutlined,
  CheckCircleFilled,
} from "@ant-design/icons";
import {
  Button,
  Input,
  Modal,
  PageHeader,
  Select,
  Spin,
  Table,
  message,
  Typography,
  Tag,
  Form,
  Checkbox,
  InputNumber,
} from "antd";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import axiosInstance, {
  axiosInstancePlain,
  setAuthToken,
} from "../utils/axiosConfig"; // Update the path as necessary
import statMap, { defStatMap, kickerStatMap } from "../utils/statMap";
import { LeagueFormItems } from "./CreateLeaguePage";
import { RulesModal } from "./HomePage";
const { Search } = Input;
const { Title, Text } = Typography;

const getPayout = (rank) => {
  switch (rank) {
    case 1:
      return "$1800";
    case 2:
      return "$1175";
    case 3:
      return "$730";
    case 4:
      return "$435";
    case 5:
      return "$250";
    case 6:
      return "$110";
    default:
      return null;
  }
};

const get_scoring_values = (scoringSettings) => {
  const values = { ...scoringSettings };
  values["rushYds"] = 1 / values["rushYds"];
  values["recYds"] = 1 / values["recYds"];
  values["passYds"] = 1 / values["passYds"];
  return values;
};

const parseTeamData = (data) => {
  const res = [];
  for (let teamId in data) {
    res.push({ id: teamId, ...data[teamId] });
  }
  return res;
};

const parseDefLineupData = (data, rosterSettings) => {
  const res = [];
  for (let key in data) {
    res.push({
      position: "DEF",
      score: data[key].final_score,
      ...data[key].team,
      ...data[key].team.stats,
      ...data[key].team.count,
    });
  }
  for (let i = 0; i < rosterSettings.df - res.length; i++) {
    res.push({ position: "DEF" });
  }

  return res;
};

const parseKickerLineupData = (data, rosterSettings) => {
  const roster = {
    k: [],
  };

  for (let key in data) {
    if (roster[data[key].player.position.toLowerCase()]) {
      roster[data[key].player.position.toLowerCase()].push({
        score: data[key].final_score,
        ...data[key].player,
        ...data[key].player.stats,
        ...data[key].player.count,
      });
    }
  }

  const res = [];
  for (let key in roster) {
    for (let player of roster[key]) {
      res.push(player);
    }
    for (let i = 0; i < rosterSettings[key] - roster[key].length; i++) {
      res.push({ position: key.toUpperCase() });
    }
  }

  return res;
};

const parseLineupData = (data, rosterSettings) => {
  const roster = {
    qb: [],
    rb: [],
    wr: [],
    te: [],
  };

  for (let key in data) {
    if (roster[data[key].player.position.toLowerCase()]) {
      roster[data[key].player.position.toLowerCase()].push({
        score: data[key].final_score,
        ...data[key].player,
        ...data[key].player.stats,
        ...data[key].player.count,
      });
    }
  }

  const res = [];
  for (let key in roster) {
    for (let player of roster[key]) {
      res.push(player);
    }
    for (let i = 0; i < rosterSettings[key] - roster[key].length; i++) {
      res.push({ position: key.toUpperCase() });
    }
  }

  return res;
};

const parsePlayers = (data) => {
  const res = {};
  for (let player of data) {
    const pos = player.player.position;
    const obj = {
      score: player.score,
      opponent: player.opponent,
      startTimestamp: player.startTimestamp,
      ...player.player,
      ...player.stats,
    };
    try {
      res[pos].push(obj);
    } catch {
      res[pos] = [obj];
    }
  }
  return res;
};

const parseDefenses = (data) => {
  const res = [];
  for (let player of data) {
    const obj = {
      score: player.score,
      opponent: player.opponent,
      startTimestamp: player.startTimestamp,
      ...player.team,
      ...player.stats,
    };
    res.push(obj);
  }
  return res;
};

function convertUtcToUserTimezone(utcTimestamp) {
  // If your timestamp is in seconds
  const date = new Date(utcTimestamp * 1000);

  // Create a formatter (use options to format as desired)
  const options = {
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };
  const formatter = new Intl.DateTimeFormat("en-US", options);

  // Format the date to a string
  return formatter.format(date);
}

const getStatCols = (withSorting) => {
  return Object.keys(statMap)
    .filter((key) => statMap[key])
    .map((key) => ({
      title: statMap[key],
      dataIndex: key,
      key: key,
      align: "right",
      ...(withSorting
        ? {
            sorter: (a, b) => a[key] - b[key],
            sortDirections: ["descending", "ascending"],
          }
        : {}),
    }));
};

const getKickerStatCols = (withSorting) => {
  return Object.keys(kickerStatMap)
    .filter((key) => kickerStatMap[key])
    .map((key) => ({
      title: kickerStatMap[key],
      dataIndex: key,
      key: key,
      align: "right",
      ...(withSorting
        ? {
            sorter: (a, b) => a[key] - b[key],
            sortDirections: ["descending", "ascending"],
          }
        : {}),
    }));
};

const getDefStatCols = (rush_thresh, rec_thresh, withSorting) => {
  const cols = Object.keys(defStatMap)
    .filter((key) => defStatMap[key])
    .map((key) => ({
      title: defStatMap[key],
      dataIndex: key,
      key: key,
      align: "right",
      ...(withSorting
        ? {
            sorter: (a, b) => a[key] - b[key],
            sortDirections: ["descending", "ascending"],
          }
        : {}),
    }));

  const rushYdsAgainstCol = {
    title: "Rush Yd Against",
    key: "rush_yds_against",
    dataIndex: "rush_yds_against",
    align: "right",
    ...(withSorting
      ? {
          sorter: (a, b) => a["rush_yds_against"] - b["rush_yds_against"],
          sortDirections: ["descending", "ascending"],
        }
      : {}),
    render: (val) => (
      <div
        style={
          0 < val < rush_thresh ? { color: "green", fontWeight: "bold" } : {}
        }
      >
        {val}
      </div>
    ),
  };

  const recYdsAgainstCol = {
    title: "Rec Yd Against",
    key: "rec_yds_against",
    dataIndex: "rec_yds_against",
    align: "right",
    ...(withSorting
      ? {
          sorter: (a, b) => a["rec_yds_against"] - b["rec_yds_against"],
          sortDirections: ["descending", "ascending"],
        }
      : {}),
    render: (val) => (
      <div
        style={
          0 < val < rec_thresh ? { color: "green", fontWeight: "bold" } : {}
        }
      >
        {val}
      </div>
    ),
  };

  const ptsAgainstCol = {
    title: "Shutout",
    key: "shutout",
    align: "right",
    ...(withSorting
      ? {
          sorter: (a, b) => a.shutout - b.shutout,
          sortDirections: ["descending", "ascending"],
        }
      : {}),
    render: (_, record) =>
      record.shutout === 1 ? (
        <CheckCircleFilled style={{ color: "green", fontSize: "1.5rem" }} />
      ) : null,
  };

  return [...cols, rushYdsAgainstCol, recYdsAgainstCol, ptsAgainstCol];
};

const formatDecimal = (value) => {
  try {
    return value.toFixed(0);
  } catch {
    return value;
  }
};

const ViewLeaguePage = () => {
  const [loading, setLoading] = useState(true);
  const [loadingAvail, setLoadingAvail] = useState(true);
  const [loadingLeague, setLoadingLeague] = useState(true);
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [loadingUpdateSettings, setLoadingUpdateSettings] = useState(false);

  const [leagueData, setLeagueData] = useState({});
  const [teamData, setTeamData] = useState([]);
  const [myTeamData, setMyTeamData] = useState({});
  const [curWeek, setCurWeek] = useState(null);
  const [availablePlayers, setAvailablePlayers] = useState([]);
  const [availableDefenses, setAvailableDefenses] = useState([]);

  const [filteredPlayers, setFilteredPlayers] = useState([]);
  const [filteredDefenses, setFilteredDefenses] = useState([]);
  const [filteredTeamData, setFilteredTeamData] = useState([]);

  const [playerSearchValue, setPlayerSearchValue] = useState("");
  const [defenseSearchValue, setDefenseSearchValue] = useState("");
  const [teamSearchValue, setTeamSearchValue] = useState("");

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [rulesModalVisible, setRulesModalVisible] = useState(false);

  const [selectedView, setSelectedView] = useState("myTeam");
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [selectedWeek, setSelectedWeek] = useState(null);

  const [playerToDrop, setPlayerToDrop] = useState(null);
  const [defenseToDrop, setDefenseToDrop] = useState(null);
  const [positionToAdd, setPositionToAdd] = useState(null);

  const [isSwapModalVisible, setIsSwapModalVisible] = useState(false);
  const [isDefSwapModalVisible, setIsDefSwapModalVisible] = useState(false);
  const [myTeamCols, setMyTeamCols] = useState([]);

  const { token } = useContext(AuthContext);
  const navigate = useNavigate();
  const { teamId } = useParams();

  useEffect(() => {
    if (token) {
      setAuthToken(token);
      fetchData();
      fetchLeagueData();
    }
  }, [token]);

  useEffect(() => {
    if (playerSearchValue) {
      const escapedSearchValue = playerSearchValue.replace(
        /[.*+?^${}()|[\]\\]/g,
        "\\$&"
      );
      const regex = new RegExp(escapedSearchValue, "i"); // 'i' for case-insensitive
      const filteredPlayersTemp = {};
      for (let posKey in availablePlayers) {
        const playerData = availablePlayers[posKey];
        filteredPlayersTemp[posKey] = playerData.filter((player) =>
          regex.test(player.name)
        );
      }
      setFilteredPlayers(filteredPlayersTemp);
    } else {
      setFilteredPlayers(availablePlayers);
    }
  }, [playerSearchValue]);

  useEffect(() => {
    setFilteredPlayers(availablePlayers);
  }, [availablePlayers]);

  useEffect(() => {
    if (defenseSearchValue) {
      const escapedSearchValue = defenseSearchValue.replace(
        /[.*+?^${}()|[\]\\]/g,
        "\\$&"
      );
      const regex = new RegExp(escapedSearchValue, "i"); // 'i' for case-insensitive
      setFilteredDefenses(
        availableDefenses.filter((player) => regex.test(player.name))
      );
    } else {
      setFilteredDefenses(availableDefenses);
    }
  }, [defenseSearchValue]);

  useEffect(() => {
    setFilteredDefenses(availableDefenses);
  }, [availableDefenses]);

  useEffect(() => {
    if (teamSearchValue) {
      const escapedSearchValue = teamSearchValue.replace(
        /[.*+?^${}()|[\]\\]/g,
        "\\$&"
      );
      const regex = new RegExp(escapedSearchValue, "i"); // 'i' for case-insensitive
      setFilteredTeamData(teamData.filter((team) => regex.test(team.teamName)));
    } else {
      setFilteredTeamData(teamData);
    }
  }, [teamSearchValue]);

  useEffect(() => {
    setFilteredTeamData(teamData);
  }, [teamData]);

  useEffect(() => {
    const cols = [...playerCols];

    if (leagueData && !leagueData.rostersLocked) {
      cols.unshift({
        title: "Swap",
        key: "action",
        render: (_, record) => (
          <Button
            style={{ background: record.id ? "none" : "#02A43B" }}
            icon={
              record.id ? (
                <SwapOutlined />
              ) : (
                <PlusOutlined style={{ color: "white" }} />
              )
            }
            onClick={() => {
              if (!!record.id) {
                setPlayerToDrop(record.id);
              }
              setPositionToAdd(record.position);
              setIsSwapModalVisible(true);
            }}
          />
        ),
        width: "60px",
      });
    }

    setMyTeamCols(cols);
  }, [leagueData]);

  useEffect(() => console.log(myTeamData), [myTeamData]);

  const fetchData = async () => {
    setLoading(true);
    setLoadingAvail(true);

    try {
      // Fetch teams and standings
      const [teamResponse, weekNoResponse] = await Promise.all([
        axiosInstance.get(`get-total-team-points/${teamId}`),
        axiosInstance.get(`/weekNo`),
      ]);

      setMyTeamData(teamResponse.data);
      setLeagueData(teamResponse.data.league);
      setCurWeek(weekNoResponse.data);
      setLoading(false);

      const availablePlayersResponse = await axiosInstance.get(
        `available-players/${teamResponse.data.id}`
      );
      setAvailablePlayers(parsePlayers(availablePlayersResponse.data.players));
      setAvailableDefenses(
        parseDefenses(availablePlayersResponse.data.defenses)
      );
      setLoadingAvail(false);
    } catch (error) {
      // navigate("/join-league");
      // message.error(`Failed to load team: ${error.response.data.detail}`);
    }
  };

  const fetchLeagueData = async () => {
    setLoadingLeague(true);
    try {
      const leagueResponse = await axiosInstance.get(
        `get-league-total-info/${process.env.REACT_APP_LEAGUE_ID}`
      );
      setTeamData(parseTeamData(leagueResponse.data.teams));
    } catch (error) {
      // message.error(`Failed to load team: ${error.response.data.detail}`);
    }

    setLoadingLeague(false);
  };

  const showTeamModal = (team) => {
    setSelectedTeam(team);
    setIsModalVisible(true);
  };

  const handleAddPlayer = async (playerId) => {
    // API call logic to add the player
    try {
      setLoadingAdd(true);
      if (playerToDrop) {
        // make swap call
        if (positionToAdd === "K") {
          await axiosInstance.put(`teams/${myTeamData.id}/swap-kicker`, {
            playerDrop: playerToDrop,
            playerAdd: playerId,
          });
        } else {
          await axiosInstance.put(`teams/${myTeamData.id}/swap-player`, {
            playerDrop: playerToDrop,
            playerAdd: playerId,
          });
        }
      } else {
        // make add call
        if (positionToAdd === "K") {
          await axiosInstancePlain.put(
            `teams/${myTeamData.id}/add-kicker`,
            playerId.toString()
          );
        } else {
          await axiosInstancePlain.put(
            `teams/${myTeamData.id}/add-player`,
            playerId.toString()
          );
        }
      }
      // After successful API call, close the modal and reset state
      message.success("Player added successfully");
    } catch (error) {
      message.error(`Failed to add player: ${error.response.data.detail}`);
    }
    setLoadingAdd(false);
    setIsSwapModalVisible(false);
    setPlayerToDrop(null);
    setPositionToAdd(null);
    setPlayerSearchValue("");
    fetchData();
  };

  const handleAddDefense = async (teamId) => {
    // API call logic to add the player
    try {
      setLoadingAdd(true);
      if (defenseToDrop) {
        // make swap call
        await axiosInstance.put(`teams/${myTeamData.id}/swap-defense`, {
          defenseDrop: defenseToDrop,
          defenseAdd: teamId,
        });
      } else {
        // make add call
        await axiosInstancePlain.put(
          `teams/${myTeamData.id}/add-defense`,
          teamId.toString()
        );
      }
      // After successful API call, close the modal and reset state
      message.success("Player added successfully");
    } catch (error) {
      message.error(`Failed to add player: ${error.response.data.detail}`);
    }
    setLoadingAdd(false);
    setIsDefSwapModalVisible(false);
    setDefenseToDrop(null);
    setDefenseSearchValue("");
    fetchData();
  };

  const handleUpdateLeagueSettings = async (values) => {
    setLoadingUpdateSettings(true);

    // Here you would typically make an API request to create the league
    const payload = {
      passYds: 1 / values.passYds,
      passTds: values.passTds,
      ints: values.ints,
      passTwoPtConv: values.passTwoPtConv,
      rushYds: 1 / values.rushYds,
      rushTds: values.rushTds,
      rushTwoPtConv: values.rushTwoPtConv,
      recYds: 1 / values.recYds,
      recs: values.recs,
      recTds: values.recTds,
      recTwoPtConv: values.recTwoPtConv,
      otherTds: values.otherTds,
      fumbles: values.fumbles,
      // defensive
      def_td: values.def_td,
      xpRetTwoPtConv: values.xpRetTwoPtConv,
      ints_def: values.ints_def,
      sacks: values.sacks,
      fum_rec: values.fum_rec,
      shutout: values.shutout,
      pts_against_1: values.pts_against_1,
      pts_against_7: values.pts_against_7,
      pts_against_14: values.pts_against_14,
      pts_against_21: values.pts_against_21,
      pts_against_28: values.pts_against_28,
      pts_against_35: values.pts_against_35,
    };

    try {
      await axiosInstance.put(
        `/leagues/${leagueData.id}/update-scoring-settings`,
        payload
      );
      message.success("Scoring settings updated successfully!");
      fetchData();
      fetchLeagueData();
    } catch (error) {
      message.error(
        `Failed to update scoring settings: ${error.response.data.detail}`
      );
    }
    setLoadingUpdateSettings(false);
  };

  const handleUpdateTeamData = async (values) => {
    setLoadingUpdateSettings(true);

    if (values.teamName !== myTeamData.teamName) {
      try {
        await axiosInstance.put(
          `/teams/${myTeamData.id}/update-name`,
          values.teamName
        );
        message.success("Team name updated successfully!");
      } catch (error) {
        message.error(
          `Failed to update team name: ${error.response.data.detail}`
        );
      }
    }

    if (values.superBowlTotal !== myTeamData.superBowlTotal) {
      try {
        await axiosInstance.put(
          `/teams/${myTeamData.id}/update-super-bowl-total`,
          values.superBowlTotal
        );
        message.success("Super bowl total prediction updated successfully!");
        fetchData();
        fetchLeagueData();
      } catch (error) {
        message.error(
          `Failed to update team name: ${error.response.data.detail}`
        );
      }
    }

    fetchData();
    fetchLeagueData();
    setLoadingUpdateSettings(false);
  };

  const handleUpdateSettings = async (values) => {
    setLoadingUpdateSettings(true);

    if (values.leagueName !== leagueData.name) {
      try {
        await axiosInstance.put(
          `/leagues/${leagueData.id}/update-name`,
          values.leagueName
        );
        message.success("League name updated successfully!");
      } catch (error) {
        message.error(
          `Failed to update league name: ${error.response.data.detail}`
        );
      }
    }

    if (values.lockLeague !== leagueData.locked) {
      try {
        await axiosInstance.put(
          `/leagues/${leagueData.id}/lock-league`,
          values.lockLeague
        );
        message.success(
          `League lock${values.lockLeague ? "ed" : " removed"} successfully!`
        );
      } catch (error) {
        message.error(`Failed to lock league: ${error.response.data.detail}`);
      }
    }

    if (values.lockRosters !== leagueData.rostersLocked) {
      try {
        await axiosInstance.put(
          `/leagues/${leagueData.id}/lock-rosters`,
          values.lockRosters
        );
        message.success(
          `Rosters lock${values.lockRosters ? "ed" : " removed"} successfully!`
        );
      } catch (error) {
        message.error(`Failed to lock rosters: ${error.response.data.detail}`);
      }
    }
    fetchData();
    fetchLeagueData();
    setLoadingUpdateSettings(false);
  };

  const handlePaidStatusChange = async (teamId, paidStatus) => {
    try {
      setTeamData((prev) =>
        prev.map((team) => {
          if (team.id === teamId) {
            team.paid = paidStatus;
          }
          return team;
        })
      );

      const response = await axiosInstance.put(
        `/teams/${teamId}/update-paid`,
        paidStatus
      );
      // Handle successful response
      message.success(response.message);
    } catch (error) {
      message.error(`Error updating paid status: ${error}`);
      setTeamData((prev) =>
        prev.map((team) => {
          if (team.id === teamId) {
            team.paid = !paidStatus;
          }
          return team;
        })
      );
    }
  };

  const onPaidStatusChange = (checked, teamId) => {
    handlePaidStatusChange(teamId, checked);
  };

  const leagueCols = useMemo(
    () => [
      {
        title: "Team Name",
        dataIndex: "teamName",
        key: "teamName",
      },
      {
        title: "Rank",
        dataIndex: "rank",
        key: "rank",
      },
      {
        title: "Payout",
        key: "payout",
        render: (_, record) => getPayout(record.rank),
      },
      {
        title: "Total Points",
        dataIndex: "score",
        key: "score",
        render: (score) => formatDecimal(score),
      },
      {
        title: "Super Bowl Total",
        dataIndex: "superBowlTotal",
        key: "superBowlTotal",
      },
      // Add other relevant columns
      {
        title: "Paid?",
        key: "paid",
        disabled:
          myTeamData && leagueData
            ? myTeamData.userId !== leagueData.commissionerId
            : true,
        render: (_, record) => (
          <Checkbox
            onChange={(e) => onPaidStatusChange(e.target.checked, record.id)}
            disabled={myTeamData.userId !== leagueData.commissionerId}
            checked={record.paid}
          />
        ),
      },
      {
        title: "Team Claimed?",
        key: "claimed",
        disabled: true,
        render: (_, record) => (
          <Checkbox disabled={true} checked={record.userId} />
        ),
      },
      {
        title: "Action",
        key: "action",
        render: (_, record) => (
          <Button onClick={() => showTeamModal(record)}>View Team</Button>
        ),
      },
    ],
    [teamData, leagueData]
  );

  const gameCols = [
    {
      title: "Opponent",
      key: "opponent",
      render: (_, record) =>
        record.opponent
          ? `${
              record.opponent === "BYE" || record.opponent === "--"
                ? ""
                : record.isHome
                ? "vs "
                : "@ "
            }${record.opponent}`
          : null,
    },
    {
      title: "Gametime",
      key: "startTime",
      render: (_, record) =>
        record.startTimestamp > 0
          ? convertUtcToUserTimezone(record.startTimestamp)
          : null,
    },
  ];

  const playerCols = [
    {
      title: "Position",
      dataIndex: "position",
      key: "position",
      width: "40px",
    },
    {
      title: "Player",
      key: "player",
      render: (_, record) => (
        <div
          style={{
            display: record.image ? "flex" : "none",
            gap: "16px",
            alignItems: "center",
            fontWeight: "bold",
          }}
        >
          <img
            src={record.image}
            alt="Player"
            // className="hide-on-mobile"
            style={{ width: "40px", height: "auto" }} // Adjust size as needed
          />
          {record.name}
        </div>
      ),
    },
    {
      title: "Points",
      dataIndex: "score",
      key: "score",
      render: (score) => formatDecimal(score),
    },
    {
      title: "Teams Rostered",
      dataIndex: "count",
      key: "count",
    },
    {
      title: "Team",
      dataIndex: "team",
      key: "team",
    },
    ...gameCols,
    // Add other rel
  ];

  const defenseCols = [
    {
      title: "Position",
      key: "position-def",
      width: "40px",
      render: () => "DEF",
    },
    {
      title: "Team",
      key: "team",
      render: (_, record) => (
        <div
          style={{
            display: record.logo ? "flex" : "none",
            gap: "16px",
            alignItems: "center",
            fontWeight: "bold",
          }}
        >
          <img
            src={record.logo}
            alt="Logo"
            // className="hide-on-mobile"
            style={{ width: "40px", height: "auto" }} // Adjust size as needed
          />
          {record.name}
        </div>
      ),
    },
    {
      title: "Points",
      dataIndex: "score",
      key: "score",
      render: (score) => formatDecimal(score),
    },
    {
      title: "Teams Rostered",
      dataIndex: "count",
      key: "count",
    },
    ...gameCols,
    // Add other rel
  ];

  const myDefCols = useMemo(() => {
    const cols = [...defenseCols];

    if (leagueData && !leagueData.rostersLocked) {
      cols.unshift({
        title: "Swap",
        key: "action",
        render: (_, record) => (
          <Button
            style={{ background: record.id ? "none" : "#02A43B" }}
            icon={
              record.id ? (
                <SwapOutlined />
              ) : (
                <PlusOutlined style={{ color: "white" }} />
              )
            }
            onClick={() => {
              if (!!record.id) {
                setDefenseToDrop(record.id);
              }
              setIsDefSwapModalVisible(true);
            }}
          />
        ),
        width: "60px",
      });
    }
    return cols;
  }, [myTeamData]);

  const myTeamWithStatsCols = [...myTeamCols, ...getStatCols(false)];

  const myKickerWithStatsCols = [...myTeamCols, ...getKickerStatCols(false)];

  const myDefWithStatsCols = useMemo(
    () => [
      ...myDefCols,
      ...getDefStatCols(
        leagueData?.scoringSettings?.rush_yds_against_bonus_threshold,
        leagueData?.scoringSettings?.rec_yds_against_bonus_threshold,
        false
      ),
    ],
    [leagueData]
  );

  const availablePlayersCols = [
    {
      title: "Add",
      dataIndex: "id",
      key: "add",
      render: (id) => (
        <Button
          style={{ background: "#02A43B" }}
          icon={<PlusOutlined style={{ color: "white" }} />}
          onClick={() => handleAddPlayer(id)}
        />
      ),
      width: "60px",
    },
    {
      title: "Position",
      dataIndex: "position",
      key: "position",
      width: "40px",
    },
    {
      title: "Player",
      key: "player",
      sorter: (a, b) => (a.name > b.name ? -1 : 1),
      sortDirections: ["descend", "ascend"],
      render: (_, record) => (
        <div
          style={{
            display: record.image ? "flex" : "none",
            gap: "16px",
            alignItems: "center",
            fontWeight: "bold",
          }}
        >
          <img
            src={record.image}
            alt="Player"
            className="hide-on-mobile"
            style={{ width: "40px", height: "auto" }} // Adjust size as needed
          />
          {record.name}
        </div>
      ),
    },
    {
      title: "Points",
      dataIndex: "score",
      key: "score",
      sorter: (a, b) => a.score - b.score,
      sortDirections: ["descend", "ascend"],
      render: (score) => formatDecimal(score),
    },
    {
      title: "Team",
      dataIndex: "team",
      key: "team",
      sorter: (a, b) => (a.team > b.team ? -1 : 1),
      sortDirections: ["descend", "ascend"],
    },
    ...gameCols,
  ];

  const availableDefensesCols = [
    {
      title: "Add",
      dataIndex: "id",
      key: "add",
      render: (id) => (
        <Button
          style={{ background: "#02A43B" }}
          icon={<PlusOutlined style={{ color: "white" }} />}
          onClick={() => handleAddDefense(id)}
        />
      ),
      width: "60px",
    },
    {
      title: "Position",
      key: "position",
      width: "40px",
      render: () => "DEF",
    },
    {
      title: "Team",
      key: "team",
      sorter: (a, b) => (a.name > b.name ? -1 : 1),
      sortDirections: ["descend", "ascend"],
      render: (_, record) => (
        <div
          style={{
            display: record.logo ? "flex" : "none",
            gap: "16px",
            alignItems: "center",
            fontWeight: "bold",
          }}
        >
          <img
            src={record.logo}
            alt="Logo"
            // className="hide-on-mobile"
            style={{ width: "40px", height: "auto" }} // Adjust size as needed
          />
          {record.name}
        </div>
      ),
    },
    {
      title: "Points",
      dataIndex: "score",
      key: "score",
      sorter: (a, b) => a.score - b.score,
      sortDirections: ["descend", "ascend"],
      render: (score) => formatDecimal(score),
    },
    ...gameCols,
  ];

  const availablePlayersWithStatsCols = [
    ...availablePlayersCols,
    ...getStatCols(true),
  ];

  const availableKickersWithStatsCols = [
    ...availablePlayersCols,
    ...getKickerStatCols(true),
  ];

  const availableDefensesWithStatsCols = [
    ...availableDefensesCols,
    ...getDefStatCols(true),
  ];

  return (
    <div>
      {loading ? (
        <Spin size="large" />
      ) : (
        <>
          <PageHeader
            title={`${leagueData.name}`}
            style={{ marginBottom: "20px" }}
          />
          <div className="view-league-buttons">
            <Button.Group>
              <Button
                type={selectedView === "myTeam" ? "primary" : "default"}
                onClick={() => setSelectedView("myTeam")}
                size="large"
              >
                My Team
              </Button>
              <Button
                type={
                  selectedView === "leagueStandings" ? "primary" : "default"
                }
                onClick={() => setSelectedView("leagueStandings")}
                size="large"
              >
                Standings
              </Button>
              <Button
                type={selectedView === "settings" ? "primary" : "default"}
                onClick={() => setSelectedView("settings")}
                size="large"
              >
                Settings
              </Button>
            </Button.Group>
          </div>
          {selectedView === "settings" && (
            <>
              <Form
                layout="vertical"
                onFinish={handleUpdateTeamData}
                style={{ maxWidth: "500px", paddingBottom: "16px" }}
                initialValues={{
                  teamName: myTeamData.teamName,
                  superBowlTotal: myTeamData.superBowlTotal,
                }}
              >
                <h2>Team Settings</h2>
                <Form.Item label="Team Name" name="teamName">
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Super Bowl Total Prediction"
                  style={{ width: "100%" }}
                  name="superBowlTotal"
                >
                  <InputNumber disabled={leagueData.rostersLocked} min={0} />
                </Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loadingUpdateSettings}
                >
                  Update Team
                </Button>
              </Form>

              <Form
                disabled={myTeamData.userId !== leagueData.commissionerId}
                layout="vertical"
                onFinish={handleUpdateSettings}
                style={{ maxWidth: "500px" }}
                initialValues={{
                  leagueName: leagueData.name,
                  lockLeague: leagueData.locked,
                  lockRosters: leagueData.rostersLocked,
                }}
              >
                <h2>League Settings</h2>
                <Form.Item label="League Name" name="leagueName">
                  <Input />
                </Form.Item>

                <Form.Item name="lockLeague" valuePropName="checked">
                  <Checkbox>Lock League?</Checkbox>
                </Form.Item>
                <Form.Item name="lockRosters" valuePropName="checked">
                  <Checkbox>Lock Rosters?</Checkbox>
                </Form.Item>
                {myTeamData.userId === leagueData.commissionerId && (
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={loadingUpdateSettings}
                    >
                      Update League
                    </Button>
                  </Form.Item>
                )}
              </Form>

              <h2>Scoring Settings</h2>
              <Button
                onClick={() => setRulesModalVisible(true)}
                style={{ marginBottom: 20 }}
                type="primary"
              >
                View All Rules
              </Button>
              <Form
                disabled={myTeamData.userId !== leagueData.commissionerId}
                layout="vertical"
                onFinish={handleUpdateLeagueSettings}
                style={{ maxWidth: "500px" }}
                initialValues={{
                  ...get_scoring_values(leagueData.scoringSettings),
                }}
              >
                <h4>
                  Note that a point is only counted when a player reaches the
                  threshold for a point; for example, a player rushing for 59
                  yards will only receive 5 points, and not 5.9.
                </h4>
                <LeagueFormItems />
                {myTeamData.userId === leagueData.commissionerId && (
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={loadingUpdateSettings}
                    >
                      Update Scoring Settings
                    </Button>
                  </Form.Item>
                )}
              </Form>
            </>
          )}
          {selectedView === "leagueStandings" &&
            (loadingLeague ? (
              <Spin size="large" />
            ) : (
              <>
                <Title level={2}>League Standings</Title>
                <Search
                  placeholder="Search for teams"
                  onChange={(e) => setTeamSearchValue(e.target.value)}
                  style={{ marginBottom: 20, maxWidth: 400 }}
                />

                <Table
                  rowClassName={(record) =>
                    record.userId === myTeamData.userId
                      ? "table-row-highlight"
                      : ""
                  }
                  dataSource={filteredTeamData}
                  columns={leagueCols}
                  rowKey="id"
                  className="overflow-table"
                  pagination={false}
                />
              </>
            ))}
          {selectedView === "myTeam" && myTeamData && (
            <>
              <Title level={2}>{myTeamData.teamName}</Title>
              <Tag
                color="blue"
                style={{
                  fontSize: "24px",
                  fontWeight: "bold",
                  padding: "16px",
                  margin: "0 0 16px 0",
                }}
              >
                Score: {formatDecimal(myTeamData.score)}
              </Tag>

              <Title level={4}>Super Bowl Total Score</Title>
              <Tag
                color="black"
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  padding: "8px",
                  margin: "0 0 16px 0",
                }}
              >
                Guess: {myTeamData.superBowlTotal}
              </Tag>

              <Title level={3}>Roster</Title>
              <Title level={4}>Select Week</Title>
              <Select
                defaultValue={curWeek}
                style={{ width: 120, marginBottom: 20 }}
                onChange={(value) => setSelectedWeek(value)}
              >
                {Object.keys(myTeamData.lineups).map((week) => (
                  <Select.Option key={week} value={week}>
                    Week {week}
                  </Select.Option>
                ))}
              </Select>
              <Table
                dataSource={parseLineupData(
                  myTeamData.lineups[selectedWeek || curWeek],
                  leagueData.rosterSettings
                )}
                columns={myTeamWithStatsCols}
                rowKey="id"
                className="overflow-table"
                pagination={false}
              />

              {leagueData.rosterSettings.df > 0 ? (
                <>
                  <Title level={3}>Defense</Title>
                  <Table
                    dataSource={parseDefLineupData(
                      myTeamData.defenses[selectedWeek || curWeek],
                      leagueData.rosterSettings
                    )}
                    columns={myDefWithStatsCols}
                    rowKey="id"
                    className="overflow-table"
                    pagination={false}
                  />
                </>
              ) : null}
              {leagueData.rosterSettings.k > 0 ? (
                <>
                  <Title level={3}>Kicker</Title>
                  <Table
                    dataSource={parseKickerLineupData(
                      myTeamData.lineups[selectedWeek || curWeek],
                      leagueData.rosterSettings
                    )}
                    columns={myKickerWithStatsCols}
                    rowKey="id"
                    className="overflow-table"
                    pagination={false}
                  />
                </>
              ) : null}
            </>
          )}
          {selectedTeam !== null ? (
            <Modal
              title={selectedTeam.teamName}
              visible={isModalVisible}
              width={700} // Adjust the width as needed
              onCancel={() => setIsModalVisible(false)}
              footer={
                <Button onClick={() => setIsModalVisible(false)}>Close</Button>
              }
            >
              <Select
                defaultValue={curWeek}
                style={{ width: 120, marginBottom: 20 }}
                onChange={(value) => setSelectedWeek(value)}
              >
                {Object.keys(selectedTeam.lineups).map((week) => (
                  <Select.Option key={week} value={week}>
                    Week {week}
                  </Select.Option>
                ))}
              </Select>

              <Table
                dataSource={parseLineupData(
                  selectedTeam.lineups[selectedWeek || curWeek],
                  leagueData.rosterSettings
                )}
                columns={myTeamWithStatsCols}
                pagination={false}
                className="overflow-table"
              />
              <Table
                dataSource={parseDefLineupData(
                  selectedTeam.defenses[selectedWeek || curWeek],
                  leagueData.rosterSettings
                )}
                columns={myDefWithStatsCols}
                pagination={false}
                className="overflow-table"
              />
              <Table
                dataSource={parseKickerLineupData(
                  selectedTeam.lineups[selectedWeek || curWeek],
                  leagueData.rosterSettings
                )}
                columns={myKickerWithStatsCols}
                pagination={false}
                className="overflow-table"
              />
            </Modal>
          ) : null}
          {positionToAdd !== null ? (
            <Modal
              title="Available Players"
              visible={isSwapModalVisible}
              width="80%" // Adjust as needed
              onCancel={() => {
                setPlayerToDrop(null);
                setPositionToAdd(null);
                setIsSwapModalVisible(false);
                setPlayerSearchValue("");
              }}
              footer={
                <Button
                  disabled={loadingAdd}
                  onClick={() => {
                    setPlayerToDrop(null);
                    setPositionToAdd(null);
                    setIsSwapModalVisible(false);
                    setPlayerSearchValue("");
                  }}
                >
                  Cancel
                </Button>
              }
            >
              {loadingAvail || loadingAdd ? (
                <Spin size="large" />
              ) : (
                <>
                  <Search
                    placeholder="Search for players"
                    onChange={(e) => setPlayerSearchValue(e.target.value)}
                    style={{ marginBottom: 20 }}
                  />
                  <Table
                    dataSource={filteredPlayers[positionToAdd]}
                    columns={
                      positionToAdd === "K"
                        ? availableKickersWithStatsCols
                        : availablePlayersWithStatsCols
                    }
                    rowKey="id"
                    className="overflow-table"
                  />
                </>
              )}
            </Modal>
          ) : null}
          <Modal
            title="Available Defenses"
            visible={isDefSwapModalVisible}
            width="80%" // Adjust as needed
            onCancel={() => {
              setDefenseToDrop(null);
              setIsDefSwapModalVisible(false);
              setDefenseSearchValue("");
            }}
            footer={
              <Button
                disabled={loadingAdd}
                onClick={() => {
                  setDefenseToDrop(null);
                  setIsDefSwapModalVisible(false);
                  setDefenseSearchValue("");
                }}
              >
                Cancel
              </Button>
            }
          >
            {loadingAvail || loadingAdd ? (
              <Spin size="large" />
            ) : (
              <>
                <Search
                  placeholder="Search for players"
                  onChange={(e) => setDefenseSearchValue(e.target.value)}
                  style={{ marginBottom: 20 }}
                />

                <Table
                  dataSource={filteredDefenses}
                  columns={availableDefensesWithStatsCols}
                  rowKey="id"
                  className="overflow-table"
                />
              </>
            )}
          </Modal>

          <RulesModal
            modalVisible={rulesModalVisible}
            setModalVisible={setRulesModalVisible}
          />
        </>
      )}
    </div>
  );
};

export default ViewLeaguePage;
