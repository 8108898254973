// In ./components/MainLayout.js

import React from 'react';
import { Layout } from 'antd';
import CustomHeader from './Header';
import CustomFooter from './Footer';

const { Content } = Layout;

const MainLayout = ({ children }) => {
  return (
    <Layout className="layout">
      <CustomHeader />
      <Content className="layout-spacing">
        {children}
      </Content>
      <CustomFooter />
    </Layout>
  );
};

export default MainLayout;
