import { Button, PageHeader, Spin, Table, message, Modal, Input } from "antd";
import { CopyOutlined, BarsOutlined } from "@ant-design/icons";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import axiosInstance, { setAuthToken } from "../utils/axiosConfig"; // Update the path as necessary

const parseLeagueData = (data) => {
  return data.map((team) => ({
    name: team.league.name,
    team: team.teamName,
    key: team.leagueId,
    id: team.id,
  }));
};

const LeagueTable = ({ viewLeague }) => {
  const [leagues, setLeagues] = useState([]);
  const [loading, setLoading] = useState(true);
  const { token } = useContext(AuthContext);

  useEffect(() => {
    if (token) {
      setAuthToken(token);
      fetchLeagues();
    }
  }, [token]);


  const fetchLeagues = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get("/my-teams");
      setLeagues(parseLeagueData(response.data));
      setLoading(false);
    } catch (error) {
      console.error("Error fetching league data:", error);
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "Team",
      dataIndex: "team",
      key: "team",
    },
    {
      title: "View League",
      key: "action",
      render: (text, record) => (
        <Button type="primary" icon={<BarsOutlined />} onClick={() => { console.log(record); viewLeague(record.id)}} />
      ),
    },
  ];

  return (
    <div>
      {loading ? (
        <Spin size="large" />
      ) : (
        <>
        <Table className="overflow-table" dataSource={leagues} columns={columns} rowKey="id" />
      </>
      )}
    </div>
  );
};

const ViewLeaguesPage = () => {
  const navigate = useNavigate();

  const viewLeague = (teamId) => {
    navigate(`/view-league/${teamId}`);
  };

  return (
    <>
      <PageHeader
        className="site-page-header"
        title="Your Teams"
      />
      <LeagueTable viewLeague={viewLeague} />
      <Button
        style={{ marginLeft: 8 }}
        onClick={() => navigate("/join-league")}
      >
        Add Another Team
      </Button>
    </>
  );
};

export default ViewLeaguesPage;
