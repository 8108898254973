import {
  Button,
  Form,
  Input,
  PageHeader,
  Spin,
  message,
  InputNumber,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { axiosInstancePlain, setAuthToken } from "../utils/axiosConfig";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../utils/axiosConfig";

const JoinLeaguePage = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { token } = useContext(AuthContext);

  useEffect(() => {
    if (token) {
      setAuthToken(token);
    }
  }, [token]);

  const handleSubmit = async (values) => {
    setLoading(true);

    try {
      // Here you would typically make an API request to join the league
      const data = await axiosInstance.post(
        `/leagues/join/${process.env.REACT_APP_LEAGUE_ID}`,
        { teamName: values.teamName, superBowlTotal: values.superBowlTotal }
      );

      // After the API request:
      setLoading(false);
      message.success("League joined successfully!");
      navigate(`/view-league/${data.data.id}`);
    } catch (error) {
      message.error(`Failed to join league: ${error.response.data.detail} `);
      setLoading(false);
    }
  };

  return (
    <div>
      <PageHeader
        className="site-page-header"
        title="Join a League"
        subTitle="Enter your team name. If your team already exists, please make sure the name is exactly as it appears in the Excel sheet."
      />

      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        style={{ maxWidth: "300px", margin: "auto" }}
      >
      <h4>Having trouble joining?</h4>
      <p>Contact Mike at <a href="mailto:sierrafch2121@gmail.com">sierrafch2121@gmail.com</a></p>
      
        <Form.Item
          label="Team Name"
          name="teamName"
          rules={[{ required: true, message: "Please input your team name!" }]}
        >
          <Input placeholder="Team Name" />
        </Form.Item>
        <Form.Item
          label="Your guess for the super bowl total score, without going over. This is the season-end tiebreaker!"
          name="superBowlTotal"
          rules={[
            {
              required: true,
              message:
                "Please input your guess for the super bowl total! This should be a number",
            },
          ]}
        >
          <InputNumber
            style={{ width: "100%" }}
            placeholder="Super Bowl Total"
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Join League
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default JoinLeaguePage;
