import { Button, Form, Input, InputNumber, PageHeader, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import axiosInstance, { setAuthToken } from "../utils/axiosConfig";
import { useNavigate } from "react-router-dom";

const LeagueFormItems = () => (
  <>
    <h3>Passing</h3>
    <Form.Item label="Passing Yards per Point" name="passYds">
      <InputNumber step={0.01} />
    </Form.Item>

    <Form.Item label="Passing TDs" name="passTds">
      <InputNumber step={0.1} />
    </Form.Item>

    <Form.Item label="Interceptions Thrown" name="ints">
      <InputNumber step={0.1} />
    </Form.Item>

    <Form.Item label="Passing 2-Pt Conv" name="passTwoPtConv">
      <InputNumber step={0.1} />
    </Form.Item>

    <h3>Rushing</h3>
    <Form.Item label="Rushing Yards per Point" name="rushYds">
      <InputNumber step={0.01} />
    </Form.Item>

    <Form.Item label="Rushing TDs" name="rushTds">
      <InputNumber step={0.1} />
    </Form.Item>

    <Form.Item label="Rushing 2-Pt Conv" name="rushTwoPtConv">
      <InputNumber step={0.1} />
    </Form.Item>

    <h3>Receiving</h3>
    <Form.Item label="Receiving Yards per Point" name="recYds">
      <InputNumber step={0.01} />
    </Form.Item>

    <Form.Item label="Receiving TDs" name="recTds">
      <InputNumber step={0.1} />
    </Form.Item>

    <Form.Item label="Receiving 2-Pt Conv" name="recTwoPtConv">
      <InputNumber step={0.1} />
    </Form.Item>

    <h3>Other</h3>
    <Form.Item label="Fumbles Lost" name="fumbles">
      <InputNumber step={0.1} />
    </Form.Item>

    <Form.Item label="Unordinary TDs" name="otherTds">
      <InputNumber step={0.1} />
    </Form.Item>

    <Form.Item label="Bonus for 50+ Yard Touchdown -  Pass, Rec, or Rush" name="big_play_td_bonus">
      <InputNumber step={0.1} />
    </Form.Item>

    <h3>Defense</h3>
    <Form.Item label="Touchdown" name="def_td">
      <InputNumber step={0.1} />
    </Form.Item>
    <Form.Item label="Safeties" name="safety">
      <InputNumber step={0.1} />
    </Form.Item>
    <Form.Item label="Interceptions" name="ints_def">
      <InputNumber step={0.1} />
    </Form.Item>
    <Form.Item label="Sacks" name="sacks">
      <InputNumber step={0.1} />
    </Form.Item>
    <Form.Item label="Fumbles Recovered" name="fum_rec">
      <InputNumber step={0.1} />
    </Form.Item>
    <Form.Item label="Defensive Shutout" name="shutout">
      <InputNumber step={0.1} />
    </Form.Item>
    
    <Form.Item label="Points for Less than X Rushing Yards Against" name="rush_yds_against_bonus">
      <InputNumber step={0.1} />
    </Form.Item>
    <Form.Item label="Max Rushing Yards Against for Bonus" name="rush_yds_against_bonus_threshold">
      <InputNumber step={0.1} />
    </Form.Item>
    
    <Form.Item label="Points for Less than X Receiving Yards Against" name="rec_yds_against_bonus">
      <InputNumber step={0.1} />
    </Form.Item>
    <Form.Item label="Max Receiving Yards Against for Bonus" name="rec_yds_against_bonus_threshold">
      <InputNumber step={0.1} />
    </Form.Item>

    <h3>Kickers</h3>
    <Form.Item label="FG Less than 40 Yd" name="fg">
      <InputNumber step={0.1} />
    </Form.Item>
    <Form.Item label="FG 40-49 Yd" name="fg_40">
      <InputNumber step={0.1} />
    </Form.Item>
    <Form.Item label="FG 50+ Yd" name="fg_50">
      <InputNumber step={0.1} />
    </Form.Item>
    <Form.Item label="Extra Point Made" name="xp">
      <InputNumber step={0.1} />
    </Form.Item>

    
  </>
);

const CreateLeaguePage = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { token } = useContext(AuthContext);

  useEffect(() => {
    if (token) {
      setAuthToken(token);
    }
  }, [token]);

  const handleSubmit = async (values) => {
    setLoading(true);
    console.log("Form Values:", values);

    // Here you would typically make an API request to create the league
    const payload = {
      name: values.name,
      teamName: values.teamName,
      rosterSettings: {
        qb: values.qb,
        rb: values.rb,
        wr: values.wr,
        te: values.te,
        df: values.df,
      },
      scoringSettings: {
        passYds: 1 / values.passYds,
        passTds: values.passTds,
        ints: values.ints1,
        passTwoPtConv: values.passTwoPtConv,
        rushYds: 1 / values.rushYds,
        rushTds: values.rushTds,
        rushTwoPtConv: values.rushTwoPtConv,
        recYds: 1 / values.recYds,
        recTds: values.recTds,
        recTwoPtConv: values.recTwoPtConv,
        otherTds: values.otherTds,
        fumbles: values.fumbles1,
        // defensive
        def_td: values.def_td,
        safety: values.safety,
        ints_def: values.ints_def,
        fum_rec: values.fum_rec,
        sacks: values.sacks,
        shutout: values.shutout,
        rush_yds_against_bonus_threshold:
          values.rush_yds_against_bonus_threshold,
        rec_yds_against_bonus_threshold: values.rec_yds_against_bonus_threshold,
        rush_yds_against_bonus: values.rush_yds_against_bonus,
        rec_yds_against_bonus: values.rec_yds_against_bonus,
      },
    };

    try {
      const res = await axiosInstance.post("/leagues", payload);
      message.success("League created successfully!");
      // After the API request:
      // Redirect or update state as needed
      navigate(`/view-league/${res.data.teamId}`);
    } catch (error) {
      message.error(`Failed to create league: ${error.response.data.detail}`);
    }
    setLoading(false);
  };

  return (
    <div>
      <PageHeader
        className="site-page-header"
        title="Create a League"
        subTitle="Set up your league's details"
      />
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        style={{ maxWidth: "500px", margin: "auto" }}
        initialValues={{
          qb: 3,
          rb: 4,
          wr: 4,
          te: 1,
          df: 1,
          k: 1,
          passYds: 1 / 0.04,
          passTds: 6,
          ints: -1,
          passTwoPtConv: 2,
          rushYds: 1 / 0.1,
          rushTds: 6,
          rushTwoPtConv: 2,
          recYds: 1 / 0.1,
          recTds: 6,
          recTwoPtConv: 2,
          otherTds: 6,
          fumbles: -1,
          big_play_td_bonus: 2,
          // defensive
          def_td: 6,
          safety: 2,
          ints_def: 1,
          fum_rec: 1,
          sacks: 1,
          shutout: 5,
          rush_yds_against_bonus_threshold: 100,
          rec_yds_against_bonus_threshold: 150,
          rush_yds_against_bonus: 3,
          rec_yds_against_bonus: 4,
          // kicker
          fg: 3,
          fg_40: 4,
          fg_50: 5,
          xp: 1
        }}
      >
        <h2>League Info</h2>
        <Form.Item
          label="League Name"
          name="name"
          rules={[{ required: true, message: "Please input the league name!" }]}
        >
          <Input placeholder="League Name" />
        </Form.Item>
        <Form.Item
          label="Team Name"
          name="teamName"
          rules={[{ required: true, message: "Please input your team name!" }]}
        >
          <Input placeholder="Your Team's Name" />
        </Form.Item>

        <h2>Roster Settings</h2>
        <Form.Item label="QB" name="qb">
          <InputNumber min={0} />
        </Form.Item>
        <Form.Item label="RB" name="rb">
          <InputNumber min={0} />
        </Form.Item>
        <Form.Item label="WR" name="wr">
          <InputNumber min={0} />
        </Form.Item>
        <Form.Item label="TE" name="te">
          <InputNumber min={0} />
        </Form.Item>
        <Form.Item label="DEF" name="df">
          <InputNumber min={0} />
        </Form.Item>
        <Form.Item label="K" name="k">
          <InputNumber min={0} />
        </Form.Item>

        <h2>Scoring Settings</h2>
        <LeagueFormItems />
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Create League
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export { LeagueFormItems };
export default CreateLeaguePage;
