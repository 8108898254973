const statMap = {
  passYds: "Pass Yd",
  passTds: "Pass TD",
  ints: "Ints",
  // recs: "Recs",
  recYds: "Rec Yd",
  recTds: "Rec TD",
  rushYds: "Rush Yd",
  rushTds: "Rush TD",
  fumbles: "Fum",
  otherTds: "Other TD",
  passTwoPtConv: "Pass 2PC",
  recTwoPtConv: "Rec 2PC",
  rushTwoPtConv: "Rush 2PC",
  big_play_td: "Big Play TD"
};

const defStatMap = {
  def_td: "TD",
  safety: "Safeties",
  ints_def: "Ints",
  sacks: "Sacks",
  fum_rec: "Fum Rec",
};

const kickerStatMap = {
  fg: "FG 40 and Below",
  fg_40: "FG 40-49",
  fg_50: "FG 50+",
  xp: "XP",
};

export default statMap;
export { statMap, defStatMap, kickerStatMap };
