import React, { useContext, useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { Spin } from 'antd';

const ProtectedRoute = ({ component: Component }) => {
  const { isAuthenticated } = useContext(AuthContext);
  const [isInitialRender, setIsInitialRender] = useState(true);

  useEffect(() => {
    if (isInitialRender){
      setIsInitialRender(false);
    }
  }, [])

  return isAuthenticated ? <Component /> : (isInitialRender ? <Spin size="large" /> : <Navigate to="/" />);
};

export default ProtectedRoute