import React, { createContext, useState, useEffect } from 'react';

export const AuthContext = createContext();

const DEFAULT_EXPIRY = 3600;

export const AuthProvider = ({ children }) => {
    const [authState, setAuthState] = useState({
        token: null,
        isAuthenticated: false,
    });

    useEffect(() => {
        const token = localStorage.getItem('token');
        const expiryTime = localStorage.getItem('expiryTime');
        
        if (token && expiryTime) {
            const currentTime = new Date().getTime();
            if (currentTime < expiryTime) {
                setAuthState({ token, isAuthenticated: true });
                setLogoutTimer(expiryTime - currentTime);
            } else {
                logout();
            }
        }
    }, []);

    const setToken = (token, expiresIn) => {
        const expiryTime = new Date().getTime() + (expiresIn || DEFAULT_EXPIRY) * 1000;
        localStorage.setItem('token', token);
        localStorage.setItem('expiryTime', expiryTime);
        setAuthState({
            token: token,
            isAuthenticated: !!token,
        });
        setLogoutTimer((expiresIn || DEFAULT_EXPIRY) * 1000);
    };

    const logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('expiryTime');
        setAuthState({
            token: null,
            isAuthenticated: false,
        });
    };

    const setLogoutTimer = (duration) => {
        setTimeout(logout, duration);
    };

    return (
        <AuthContext.Provider value={{ ...authState, setToken, logout }}>
            {children}
        </AuthContext.Provider>
    );
};
